import React from 'react';
import useAutocomplete from '@mui/base/useAutocomplete';
import { styled } from '@mui/system';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface ACAutocompleteProps {
  placeholder: string;
  id: string;
  isSearchIcon?: boolean;
  label?: string;
  errorMessage?: string;
  className?: string;
  readonly options: unknown[];
  onInputChange: (value: string) => void;
  getOptionLabel: (option: unknown) => string;
  renderOption: (option: unknown) => React.ReactNode;
  onSelect: (item: any) => void;
  onBlur: () => void;
  freeSolo?: boolean;
  value: string;
  disabled?: boolean;
}

const Container = styled('div')({
  position: 'relative',
  fontFamily: 'Open Sans,sans-serif',
});

const Input = styled('input')(() => ({
  width: '100%',
  padding: '8px 40px 8px 16px',
  border: '1px solid #d1d5db',
  backgroundColor: '#f7f9fc',
  color: '#111827',
  borderRadius: '0.375rem',
  fontSize: '16px',
  fontWeight: 500,
  height: '3rem',
  '&:focus': {
    ring: '1px solid #6366f1',
    borderColor: 'transparent',
    backgroundColor: '#fff',
    outline: 'none',
    border: 'none',
    boxShadow: '0 0 0 1px #6574cd',
  },
}));

const Listbox = styled('ul')(() => ({
  width: '100%',
  marginTop: '4px',
  zIndex: 1,
  position: 'absolute',
  listStyle: 'none',
  maxHeight: '20rem',
  backgroundColor: '#fff',
  borderColor: '#e4e9f2',
  borderRadius: '0.25rem',
  borderWidth: '0.0625rem',
  fontSize: '0.9375rem',
  fontWeight: 600,
  lineHeight: '1.5rem',
  overflow: 'auto',
  '& li': {
    padding: '10px',
    color: '#222b45',
  },
  '& li.Mui-focused': {
    backgroundColor: '#382ebe',
    cursor: 'pointer',
    '& span': {
      color: '#fff',
    },
  },
  '& li:active': {
    backgroundColor: '#382ebe',
    '& span': {
      color: '#fff',
    },
  },
}));

const IconContainer = styled(FontAwesomeIcon)({
  position: 'absolute',
  top: '50%',
  right: '10px',
  transform: 'translateY(-50%)',
  color: '#CCCCCC',
});

export const ACAutocomplete = ({
  placeholder,
  id,
  isSearchIcon = false,
  label,
  errorMessage,
  className,
  options,
  onInputChange,
  getOptionLabel,
  renderOption,
  onSelect,
  onBlur,
  freeSolo = true,
  value,
  disabled = false,
}: ACAutocompleteProps) => {
  const { getRootProps, getInputProps, getListboxProps, getOptionProps, groupedOptions } =
    useAutocomplete({
      id,
      options: Array.isArray(options) ? options : [],
      freeSolo: freeSolo,
      value,
      onInputChange: (_event, value) => {
        onInputChange(value);
      },
      getOptionLabel,
      onChange: (_event, item) => {
        onSelect(item);
      },
      onClose: () => {
        onBlur();
      },
      disabled: disabled,
    });

  return (
    <div className={className}>
      {label && <div className="mb-2 font-semibold">{label}</div>}

      <Container>
        <div {...getRootProps()}>
          <Input {...getInputProps()} placeholder={placeholder} />
        </div>

        {groupedOptions.length > 0 ? (
          <Listbox {...getListboxProps()}>
            {(groupedOptions as typeof options).map((option, index) => (
              <li {...getOptionProps({ option, index })} key={index}>
                {renderOption(option)}
              </li>
            ))}
          </Listbox>
        ) : null}

        {isSearchIcon && <IconContainer icon={faMagnifyingGlass} />}
      </Container>

      {errorMessage && <div className="text-[#d02b2b] font-normal mt-1">{errorMessage}</div>}
    </div>
  );
};
