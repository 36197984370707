import { useQuery } from '@tanstack/react-query';

import { staleTime } from './config/config';
import { useAxios } from 'shared/hooks';

interface GroupResponse {
  branding: {
    colour: string;
    contactUsUrl: string;
    isGroup: boolean;
    legals: string;
    logoPath: string;
    privacyUrl: string;
  };
  id: string;
  name: string;
  tpbId: string | null;
}

export const useGetBranding = (partnerId: string | null) => {
  const { get } = useAxios();

  return useQuery({
    queryKey: ['partner-branding', partnerId],
    queryFn: async () => {
      return await get<GroupResponse>(`/api/v2/partners/${partnerId}`);
    },
    enabled: !!partnerId,
    staleTime,
  });
};
